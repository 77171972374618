html, body {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f2f5f9;/* You can add global styles to this file, and also import other style files */
    font-family: Rubik !important;  
    body {
        display: block;
        margin-bottom: 88px !important;  
        overflow-x: hidden !important;
    }
    .container-fluid {
        background-color: #E5E5E5;
    }
    
    .container{
        background-color: #fff;
    }
    
    .footer {
        position:fixed;
        left: 0px;
        bottom: 0px;
        height: 88px;
        background-color: #fff;
        box-shadow: 2px 2px 5px #999;
        margin-bottom: 0px;
        width: 100%;
        padding: 20px 0;
        z-index: 1;
    }
    
    .form-check .form-check-input[type=checkbox] {
        border-radius: 0.5em;
    }
    
    .form-check .form-check-input:checked {
        background-color:#01A49A;
        border-color: #01A49A;
    }
    
    .form-check .form-check-input:focus {
        border-color: #01A49A;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(1 164 154 / 25%);  
    }
    
    .form-check .form-check-input {
        width: 1.6em;
        height: 1.6em;
        border: 2px solid rgba(206, 212, 218, 1);
    }
    
    .form-check .form-check-label {
        font-size: 16px;
        margin-left: 3px;
        margin-top: 7px;
    }
    
    .box-internacional .form-check .form-check-input {
        margin-left: 0px;
    }
    
    .box-internacional .form-check  {
        padding-left: 1em;
    }
    
    .box-internacional .form-check .form-check-label {
        margin-top: 4px;
    }
    
    .footer button.btn-outline-success, .footer button.btn-alemana {
        border-radius: 4px;
        font-weight: 500;
        font-size: 15px;
        /* width: 170px; */
    }
    
    button.btn-alemana, .footer button.btn-outline-success:hover, .btn-buscar .btn-alemana  {
        color: #fff;
        background-color: #01A49A;
        border-color: #01A49A;
    }
    
    button.btn-alemana:hover {
        background-color: #038880;
        border-color: #038880;
    }
      
    button.btn-outline-success {
        border-color: #01A49A;
        color: #01A49A;
    }
    
    .btn-outlined button{
        background-color:#fff;
        color: #01A49A;
    }
    
    .btn-outlined button:hover{
        /* background-color:#fff; */
        color: #fff;
    }
    
    .btn-buscar .btn-alemana {
        border-radius: 4px;
        font-weight: 400;
        font-size: 16px;
        width: 100px;
        padding: 0.08rem 0;
        color: #fff;
        margin-top: 16px;
    }
    
    .btn-alemana:hover {
        background-color: #038880;
        border-color: #038880;
        color: #fff;
    }
    
    .btn-cerrar button.btn-alemana {
        color: #01A49A;   
        font-weight: 500; 
    }
    
    .inline-block {
        display: inline-block;
    }
    
    .float-right {
        float: right !important;
    }
    
    .center-modal {
        position: fixed; z-index: 257;left:0; top:40%; right: 0;
    }
    
    .mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate, .mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate {
        background: #01A49A;
    }
    
    .tableCei > thead {
        background-color:#d3f0ee;
    }
    
    .tableCei th{
        color:#000;
        font-weight:500;
        font-size:14px;
        padding-left:10px !important;
        padding-right:10px !important;
        border-bottom-color: rgb(0 0 0 / 8%);
    }
    
    .tableCei tbody td{
        font-size:15px;
        font-weight:400;
        padding-left:10px !important;
        padding-right:10px !important;
        border-bottom-color: rgb(0 0 0 / 8%);
    }
    
    .tableCei tbody tr{
        height:35px;
    }
    
    .tableDetalle > thead tr{
        height: 40px;
    }
    
    .tableDetalle th{
        color:#000;
        font-weight:600;
        font-size:14px;
        padding-left:10px !important;
        padding-right:10px !important;
        border-bottom-color: rgb(0 0 0 / 8%);
    }
    
    .tableDetalle tbody td{
        font-size:15px;
        font-weight:400;
        padding-left:10px !important;
        padding-right:10px !important;
        border-bottom-color: rgb(0 0 0 / 8%);
    }
    
    .tableDetalle tbody tr{
        height:35px;
    }
    
    .condic_general{
        height:40px;
        align-items: center;
        display: inline-flex;
        background-color:#e9f7f6;
        width:100%;
        padding-left:10px;
        font-weight:500;
        font-size:14px;
    }
    
    .input-label{
        font-size: 15px !important;
    }
    
    .inputNacionalidad input{
        text-transform: capitalize;
    }
    
    .volverButton .btn-outline-success{
        border-color: #a5a5a5 !important;
        color:#a5a5a5 !important;
        width:150px;
    }
    
    .volverButton .btn-outline-success:hover{
        border-color: #a5a5a5 !important;
        color:#a5a5a5 !important;
        background-color:rgb(238, 238, 238) !important;
    }
    
    .btn-normal:hover {
        color: inherit !important;
        background:rgb(235, 235, 235);
    }
    
    
    .capitalizeName input{
        text-transform: capitalize;
    }
    
    .mat-sort-header-arrow{
        color:#857BE2 !important;
      }
    
    .tableListadoAdmisiones .mat-checkbox-frame {
        border-color: rgba(0, 0, 0, 0.24);
    }
    
    
    .tableListadoAdmisiones .mat-row td, th {
        text-align: center !important;
        vertical-align: middle !important;
    }
    
    .tableListadoAdmisiones .mat-sort-header-container {
        justify-content: center !important;
    }
    
    
    
    .tableListadoDocumentos .mat-checkbox-frame {
        border-color: rgba(0, 0, 0, 0.24);
    }
    
    
    .tableListadoDocumentos .mat-row td, th {
        text-align: center !important;
        vertical-align: middle !important;
    }
    
    .tableListadoDocumentos .mat-sort-header-container {
        justify-content: center !important;
    }
    
    .tableListadoNotas .mat-checkbox-frame {
        border-color: rgba(0, 0, 0, 0.24);
    }
    
    
    .tableListadoNotas .mat-row td, th {
        text-align: center !important;
        vertical-align: middle !important;
    }
    
    .tableListadoNotas .mat-sort-header-container {
        justify-content: center !important;
    }
    
    .iconButtonLista i{
        color:#5143D5 !important;
    }
    
    .container-admisioneslista .mat-form-field-appearance-outline .mat-form-field-label{
        font-size: 15px !important;
      }
    
    
    .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
        background-color: #857BE2;
    }
    
     .tableAdmisionesRealizadas .mat-sort-header-container{
        justify-content: center;
      }
    
    .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
        background-color: #b0b0b0 !important;
    }
    
    .spinner-docs svg{
        width:17px !important;
        height:17px !important;
      }
    
    .px-6{
        padding-left: 6rem !important;
        padding-right: 6rem !important;
    }
    
    table.mat-table {
        table-layout: fixed !important;
    }
    
    table.mat-table td.mat-column-nota {
        max-width: 500px !important;
        width: 500px !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
    }
    
    table.mat-table td.mat-column-usuario {
        width: 10% !important;
        /*width: 300px !important;*/
    }
    
    table.mat-table td.mat-column-request {
        overflow: hidden!important;
        white-space: nowrap!important;
        text-overflow: ellipsis!important;
        width: 30% !important;
    }
    
}

::ng-deep .auto-width .mat-select-value {
    max-width: 100% !important;
    width: auto !important;
}

.mat-form-field-label {
    font-size: 12px !important;
    transform: translateY(-1.28125em) translateZ(0.001px) !important;
    margin-left: 3px !important;
}

.mat-select-value-text{
    margin-left: 3px !important;
}